import SubHeader from "@/components/SubHeader";
import { checkPermission } from "@shared/utils/functions"
import { RolesAndPermissionsContainer } from "@shared/components/roles-and-permissions";

export default {
  name: "RolesAndPermissions",

  components: {
    SubHeader,
    RolesAndPermissionsContainer
  },

  title: "Roles and Permissions",

  data() {
    return {
      showAddRoleModal: false,
    };
  },

  methods: {
    checkPermission,

    handleShowModal() {
      this.showAddRoleModal = true;
    },

    handleCloseModal() {
      this.showAddRoleModal = false;
    },
  },
};
